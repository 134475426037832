import { merge } from 'lodash';
import { PropTypes } from 'prop-types';
import React, { Component } from 'react';
import ReCAPTCHA from 'react-google-recaptcha';
import { defineMessages, FormattedHTMLMessage, injectIntl, intlShape } from 'react-intl';
import styled from 'styled-components';
import Button from '../../button';
import CivilityField from './components/CivilityField';
import Fields from './components/Fields';
import Message from './components/Message';
import Messages from './components/Messages';
import OverlayUi from './components/OverlayUi';
import {
  CenterAlignWrapper,
  LegalNoticeContainer,
  MaskedContentWrapper,
  StyledWrapper,
} from './components/styles';
import { ErrorSeverity } from './constants';
import API from './ContactAPI';
import initialState from './initialState';
import buildWidgetState from './stateComposer';

const Checkbox = styled.input.attrs({
  className: props => `stx-Checkbox stx-theme-${props.theme.currentTheme}`,
})`
  margin: 1px 5px;
  vertical-align: bottom;
  &.stx-theme-TS15 {
    width: 24px;
    height: 24px;
    border-radius: 4px;
    border: 1px solid rgba(47, 43, 67, 0.10);
    background: #FFF;
    gap: 12px;
  }
`;

const DataPrivacyPolicyWrapper = styled.div.attrs({
  className: 'stx-DataPrivacyPolicyWrapper',
})`
  color: ${props => props.theme && props.theme.textColor};
  padding: 10px 0;
`;

const PrivacyPolicySumIframe = styled.iframe.attrs({
  className: 'stx-PrivacyPolicySummary',
})`
  width: 100%;
  height: 50px;
  border: none;
`;

class ContactCreation extends Component {
  static validateField(fieldCode, value) {
    let error = null;
    if (value === null || value === undefined || value === '') {
      error = <FormattedHTMLMessage id="widget.contactCreation.error.fieldRequired" />;
    }
    return error;
  }

  constructor(props) {
    super(props);
    this.captchaEl = null;
    this.state = initialState;

    window.recaptchaOptions = {
      lang: this.props.language,
    };

    const { apiKey, pointOfSales } = props;
    const restAPI = API.configure({
      apiURL: props.apiURL,
      apiKey,
      pointOfSales,
    });
    this.csrfAPI = restAPI.forResource('csrf');
    this.contactAPI = restAPI.forResource('contacts');

    this.validateCaptcha = this.validateCaptcha.bind(this);
    this.mergeState = this.mergeState.bind(this);
    this.updateState = this.updateState.bind(this);
    this.onPrivacyPolicyChangeHandler = this.onPrivacyPolicyChangeHandler.bind(this);
    this.setCaptchaEl = captchaEl => {
      this.captchaEl = captchaEl;
    };

    this.captchaResolved = token => {
      this.captchaCallback(token);
    };

    this.cachedHandlers = [];
  }

  componentWillMount() {
    this.mergeState(buildWidgetState(this.props));
    if (this.props.activeFields.indexOf('civility') >= 0 && this.props.contactTitles.length > 0) {
      const selectableTitles = this.props.contactTitles
        .filter(title => title !== 'UNDEFINED')
        .map(title => {
          return { value: title };
        });
      this.setState({
        // mergeState won't work
        contactTitles: selectableTitles,
      });
      this.mergeState({
        fields: {
          civility: {
            value: selectableTitles[0].value,
          },
        },
      });
    }
  }

  onPrivacyPolicyChangeHandler(event) {
    const target = event.target;
    this.setState(prevState => ({
      ...prevState,
      privacyPolicy: target.checked,
    }));
  }

  setLoading(isLoading) {
    this.mergeState({ loading: isLoading });
  }

  getCsrf() {
    if (this.state.csrf) {
      return Promise.resolve(this.state.csrf);
    }
    return this.csrfAPI
      .buildRequest()
      .get()
      .then(json => {
        this.mergeState({ csrf: json.token });
        return json.token;
      });
  }

  validateCaptcha() {
    if (
      this.state.captchaVerifyToken ||
      ((this.props.captchaKey === 'dummyCaptchaToken' ||
        this.props.apiKey === 'dummyCaptchaToken') &&
        this.props.invisibleCaptcha)
    ) {
      this.subscribe();
    } else {
      this.setLoading(true);
      this.captchaEl.reset();
      this.captchaEl.execute();
    }
  }

  mergeState(partialState) {
    this.setState(prevState => merge({}, prevState, partialState));
  }

  captchaCallback(token) {
    this.mergeState({ captchaVerifyToken: token });
    if (token && this.props.invisibleCaptcha) {
      this.subscribe();
    } else {
      // Expire case
    }
  }

  subscribe() {
    this.setLoading(true);

    return this.getCsrf()
      .then(csrfToken => {
        const criteria = this.state.criteria;

        const criteriaParams = Object.keys(criteria)
          .map(key => ({
            criterionIdCode: key,
            values: criteria[key].value,
          }))
          .reduce((acc, curr) => {
            acc.push(curr);
            return acc;
          }, []);

        const authorizations = this.state.authorizations;
        const authorizationParams = Object.keys(authorizations)
          .map(key => ({
            authorizationCode: key,
            allowed: authorizations[key].value,
          }))
          .reduce((acc, curr) => {
            acc.push(curr);
            return acc;
          }, []);

        const requestBody = {
          civility: this.state.fields.civility.value,
          firstName: this.state.fields.firstName.value,
          lastName: this.state.fields.lastName.value,
          email: this.state.fields.email.value,
          preferedLanguage: this.props.language,
          criteria: criteriaParams,
          authorizations: authorizationParams,
        };

        if (this.props.contactOrigin) {
          requestBody.origin = this.props.contactOrigin;
        }

        return this.contactAPI
          .buildRequest(requestBody, {
            'X-CSRF-Token': csrfToken,
            'X-Captcha-Token': this.state.captchaVerifyToken,
          })
          .post();
      })
      .then((/* contactJson */) => {
        this.setLoading(false);
        this.mergeState({
          generalMessage: {
            show: true,
            severity: ErrorSeverity.SUCCESS,
            rawMessage: 'dialog.success',
          },
          captchaVerifyToken: null,
        });
      })
      .catch(this.handleSubscribeError.bind(this));
  }

  handleSubscribeError(error) {
    this.mergeState({
      captchaVerifyToken: null,
    });
    if (error instanceof Error) {
      // Network or unknown error
      this.mergeState({
        generalMessage: {
          show: true,
          severity: ErrorSeverity.FATAL,
          rawMessage: 'error.unknown',
        },
      });
    } else {
      // Network OK, server side error
      const displayableError = (error.errors || []).find(
        el => el.errorMessage && el.errorMessage.indexOf('error.contact') >= 0,
      );
      if (displayableError) {
        if (displayableError.errorMessage === 'error.contact.invalidEmail') {
          this.mergeState({
            fields: {
              email: {
                errorMsg: (
                  <FormattedHTMLMessage
                    id={`widget.contactCreation.${displayableError.errorMessage}`}
                  />
                ),
              },
            },
          });
        } else if (displayableError.errorMessage.includes('error.contact.loginAlreadyUsed')) {
          const contactLoginURL = `//${this.props.pointOfSales}/api/1/redirect/account`;
          const message = (
            <FormattedHTMLMessage
              id={`widget.contactCreation.error.contact.loginAlreadyUsed`}
              values={{
                loginURL: contactLoginURL,
              }}
            />
          );

          this.mergeState({
            generalMessage: {
              show: true,
              severity: ErrorSeverity.WARNING,
              formattedMessage: message,
            },
          });
        }
      } else {
        this.mergeState({
          generalMessage: {
            show: true,
            severity: ErrorSeverity.FATAL,
            rawMessage: 'error.unknown',
          },
        });
      }
    }
    this.setLoading(false);
  }

  updateState(fieldName) {
    const fnKey = `UPDATE_STATE_${fieldName}`;
    if (!this.cachedHandlers[fnKey]) {
      this.cachedHandlers[fnKey] = evt => {
        const change = {};
        let value;
        if (typeof evt === 'string') {
          value = evt;
        } else {
          value = evt.target.value;
        }
        if (value === 'true') {
          value = true;
        } else if (value === 'false') {
          value = false;
        }
        const errorMsg = ContactCreation.validateField(fieldName, value);
        if (fieldName.indexOf('criteria') >= 0) {
          change.criteria = {};
          change.criteria[fieldName.split('.')[1]] = {
            value: [value],
          };
        } else if (fieldName.indexOf('authorization') >= 0) {
          change.authorizations = {};
          change.authorizations[fieldName.split('.')[1]] = { value };
        } else {
          change.fields = {};
          change.fields[fieldName] = {
            value,
            errorMsg,
          };
        }
        this.mergeState(change);
      };
    }
    return this.cachedHandlers[fnKey];
  }

  render() {
    const { privacyPolicyUrl, privacyPolicySummary, currentTheme } = this.props;

    let formValid = !this.props.activeFields
      .concat(['email'])
      .find(el => !this.state.fields[el].value);

    formValid =
      formValid &&
      (this.props.invisibleCaptcha || this.state.captchaVerifyToken) &&
      (!privacyPolicyUrl || this.state.privacyPolicy);

    const showActivityIndicator = this.state.loading;
    const messages = defineMessages({
      legalMessage: {
        id: 'widget.contactCreation.legalNotice',
        defaultMessage: '__NO_MESSAGE__',
      },
    });

    let legalNotice = this.props.intl.formatMessage(messages.legalMessage);
    if (legalNotice.indexOf('__NO_MESSAGE__') >= 0) {
      legalNotice = '';
    }

    const generalMessage = this.state.generalMessage;

    let civilityField = null;
    const activeFields = this.props.activeFields;
    if (activeFields.indexOf(CivilityField.FIELD_CODE) >= 0) {
      civilityField = (
        <CivilityField
          currentTheme={currentTheme}
          value={this.state.fields[CivilityField.FIELD_CODE].value}
          onChange={this.updateState(CivilityField.FIELD_CODE)}
          contactTitles={this.state.contactTitles}
        />
      );
    }
    const activeFieldsWithoutCivility = [];
    activeFields.forEach(element => {
      if (element !== CivilityField.FIELD_CODE) {
        activeFieldsWithoutCivility.push(element);
      }
    });

    const fields = (
      <Fields
        activeFields={activeFieldsWithoutCivility}
        civilityField={civilityField}
        fields={this.state.fields}
        criteria={this.state.criteria}
        authorizations={this.state.authorizations}
        updateState={this.updateState}
      />
    );

    let captcha = null;
    if (this.props.invisibleCaptcha) {
      captcha = (
        <ReCAPTCHA
          ref={this.setCaptchaEl}
          size={'invisible'}
          sitekey={this.props.captchaKey}
          onChange={this.captchaResolved}
        />
      );
    } else {
      captcha = (
        <CenterAlignWrapper>
          <ReCAPTCHA
            ref={this.setCaptchaEl}
            style={{ display: 'inline-block' }}
            size={'normal'}
            sitekey={this.props.captchaKey}
            onChange={this.captchaResolved}
          />
        </CenterAlignWrapper>
      );
    }

    const dataPrivacyPolicySummary = privacyPolicySummary && (
      <DataPrivacyPolicyWrapper>
        <PrivacyPolicySumIframe src={privacyPolicySummary} />
      </DataPrivacyPolicyWrapper>
    );

    const privacyPolicy = privacyPolicyUrl && (
      <CenterAlignWrapper>
        {dataPrivacyPolicySummary}
        <DataPrivacyPolicyWrapper>
          <Checkbox
            id="privacyPolicy"
            name="privacyPolicy"
            type="checkbox"
            onChange={this.onPrivacyPolicyChangeHandler}
            value={this.state.privacyPolicy}
          />
          <label htmlFor="privacyPolicy">
            <FormattedHTMLMessage
              id="widget.contactCreation.privacyPolicy1"
              defaultMessage="I have read and accept"
            />{' '}
            <a name="privacyPolicy" href={privacyPolicyUrl}>
              <FormattedHTMLMessage
                id="widget.contactCreation.privacyPolicy2"
                defaultMessage="the data privacy policy"
              />
            </a>
          </label>
        </DataPrivacyPolicyWrapper>
      </CenterAlignWrapper>
    );

    return (
      <StyledWrapper>
        <OverlayUi showActivityIndicator={showActivityIndicator}>
          {generalMessage.show && (
            <Messages
              showButton={
                generalMessage.severity !== ErrorSeverity.FATAL &&
                generalMessage.severity !== ErrorSeverity.SUCCESS
              }
              mergeState={this.mergeState}>
              <Message severity={generalMessage.severity}>
                {generalMessage.formattedMessage || (
                  <FormattedHTMLMessage
                    id={`widget.contactCreation.${generalMessage.rawMessage}`}
                  />
                )}
              </Message>
            </Messages>
          )}
        </OverlayUi>
        <MaskedContentWrapper>
          {fields}
          {captcha}

          {privacyPolicy}
          <CenterAlignWrapper>
            <Button
              id={'subscribe'}
              onClick={this.validateCaptcha}
              disabled={!formValid || showActivityIndicator}>
              {' '}
              <FormattedHTMLMessage id="widget.contactCreation.button.subscribe" />
            </Button>
          </CenterAlignWrapper>
          {legalNotice && (
            <LegalNoticeContainer id="legalNotice">{legalNotice}</LegalNoticeContainer>
          )}
        </MaskedContentWrapper>
      </StyledWrapper>
    );
  }
}

ContactCreation.propTypes = {
  pointOfSales: PropTypes.string.isRequired,
  apiURL: PropTypes.string.isRequired,
  apiKey: PropTypes.string.isRequired,
  captchaKey: PropTypes.string.isRequired,
  contactTitles: PropTypes.arrayOf(PropTypes.string),
  activeFields: PropTypes.arrayOf(PropTypes.string),
  criteriaFields: PropTypes.arrayOf(PropTypes.object),
  authorizationFields: PropTypes.arrayOf(PropTypes.object),
  contactOrigin: PropTypes.string,
  initialEmail: PropTypes.string,
  language: PropTypes.string,
  invisibleCaptcha: PropTypes.bool,
  intl: intlShape.isRequired,
  privacyPolicySummary: PropTypes.string,
  privacyPolicyUrl: PropTypes.string,
  currentTheme: PropTypes.string,
};

ContactCreation.defaultProps = {
  contactTitles: [],
  children: [],
  language: 'en',
  activeFields: [],
  criteriaFields: [],
  authorizationFields: [],
  initialEmail: '',
  contactOrigin: '',
  invisibleCaptcha: false,
  privacyPolicySummary: '',
  privacyPolicyUrl: '',
  currentTheme: 'EQUILIBRIUM'
};
export default injectIntl(ContactCreation);
