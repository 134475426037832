import { border, color, font, spacing } from './constants';

import { css } from 'styled-components';
import { lighten, darken } from 'polished';

const heights = { small: '2rem', default: '2.5rem', large: '3rem' };

const buttonStyles = css`
  appearance: none;
  position: relative;
  display: inline-block;
  box-sizing: border-box;
  height: ${heights.default};
  line-height: calc(${heights.default} - ${border.borderWidthRegular} * 2);
  max-width: 100%;
  padding: 0 ${spacing.spacingM};
  -webkit-font-smoothing: antialiased;
  -webkit-touch-callout: none;
  text-rendering: optimizeLegibility;
  cursor: pointer;
  user-select: none;
  text-align: center;
  vertical-align: middle;
  text-decoration: none;
  text-transform: none;
  outline: 0;
  color: ${props => (props.theme.button && props.theme.button.color) || '#fff'};
  background-color: ${props =>
    (props.theme.button && props.theme.button.topBackgroundColor) || '#676f7e'};
  margin: 0;
  margin-right: 1rem;

  &:last-child {
    margin-right: 0;
    ${props => props.theme.currentTheme === 'TS15' && `
      margin-bottom: 10px;
    `}
  }

  border: ${border.borderWidthRegular} solid transparent;
  border-radius: ${props => (props.theme.button && props.theme.button.borderRadius) || 0};
  background: ${props =>
    (props.theme.button && props.theme.button.backgroundColor) || color.primaryButton};

  font-size: ${font.fontSizeRegular};

  will-change: border-color, background;
  transition: border-color linear 50ms,
              background linear 50ms;

  ${props =>
    !props.disabled &&
    `
    &:hover,
    &:focus {
      color: ${(props.theme.button && props.theme.button.color) || '#fff'};
      background-color: ${lighten(
        0.05,
        (props.theme.button && props.theme.button.backgroundColor) || color.primaryButton
      )};
    }
    &:active {
      background-color: ${darken(
        0.1,
        (props.theme.button && props.theme.button.backgroundColor) || color.primaryButton
      )}
    }
  `}

  & > svg {
    vertical-align: middle;
  }

  &::-moz-focus-inner {
    border: 0;
    padding: 0;
  }

  ${props =>
    props.small &&
    `
    height: 2rem;
    padding: 0 0.5rem;
    line-height: calc(2rem - ${border.borderWidthRegular} * 2);
  `}

  ${props =>
    props.large &&
    `
    font-size: ${font.fontSizeM};
    height: 3rem;
    line-height: calc(3rem - ${border.borderWidthRegular} * 2);
  `}

  ${props =>
    !props.disabled &&
    props.neutral &&
    `
    color: #676f7e;
    background-color: #eff2f5;

    &:hover,
    &:focus {
      color: ${lighten(0.1, '#676f7e')};
      background-color: ${lighten(0.1, '#eff2f5')};
    }
    &:active {
      background-color: ${darken(0.1, '#eff2f5')}
    }
  `}

  ${props =>
    props.flat &&
    `
    background: none;
    color: ${(props.theme.button && props.theme.button.topBackgroundColor) || '#676f7e'};

    &:not(:disabled):hover,
    &:not(:disabled):focus {
      background: none;
      color: ${(props.theme.button && props.theme.button.topBackgroundColor) || '#676f7e'};
    }
  `}
  ${props =>
    props.disabled &&
    `
      background-color: ${props.theme.currentTheme === 'TS15' ? '#F6F6F8' : '#b3b3b3'};
      color: graytext;
      cursor: not-allowed;
    `}

  ${props => props.theme.currentTheme === 'TS15' && `
    width: 100%;
    border-radius: 12px;
  `}
`;

export default buttonStyles;
