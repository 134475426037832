import React from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'

const StyledElement = styled.select`
  background: transparent
    url('data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIiBlbmNvZGluZz0iaXNvLTg4NTktMSI/Pgo8c3ZnIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyIgeG1sbnM6eGxpbms9Imh0dHA6Ly93d3cudzMub3JnLzE5OTkveGxpbmsiIHZlcnNpb249IjEuMSIgaWQ9IkNhcGFfMSIgeD0iMHB4IiB5PSIwcHgiIHZpZXdCb3g9IjAgMCAxOTUuMzA0IDEyNS4zMDQiIHN0eWxlPSJlbmFibGUtYmFja2dyb3VuZDpuZXcgMCAwIDE5NS4zMDQgMTI1LjMwNDsiIHhtbDpzcGFjZT0icHJlc2VydmUiPgo8Zz4KCTxnPgoJCTxwb2x5Z29uIHBvaW50cz0iNjIuNjUyLDEwMy44OTUgMCwyMS40MDkgMTI1LjMwNCwyMS40MDkgICAiIGZpbGw9IiMzYjNkNDAiLz4KCTwvZz4KPC9nPgo8Zz4KPC9nPgo8Zz4KPC9nPgo8Zz4KPC9nPgo8Zz4KPC9nPgo8Zz4KPC9nPgo8Zz4KPC9nPgo8Zz4KPC9nPgo8Zz4KPC9nPgo8Zz4KPC9nPgo8Zz4KPC9nPgo8Zz4KPC9nPgo8Zz4KPC9nPgo8Zz4KPC9nPgo8Zz4KPC9nPgo8Zz4KPC9nPgo8L3N2Zz4K')
    center right/18px no-repeat;
  appearance: none;
  padding: 0.75em 0;
  padding-right: 22px;
  width: 100%;
  border: none;
  border-bottom: 1px solid #e6e6e6;
  outline: none;
  &:focus {
    border-color: ${props =>
    (props.theme && props.theme.button && props.theme.button.backgroundColor) || '#1a84f1'};
  }
`
const DropDown = props => {
  return (
    <StyledElement onChange={props.onSelect} value={props.value}>
      {props.children}
    </StyledElement>
  )
}

DropDown.propTypes = {
  children: PropTypes.arrayOf(PropTypes.node),
  onSelect: PropTypes.func,
  value: PropTypes.string
}

DropDown.defaultProps = {
  children: [],
  onSelect: () => {},
  value: ''
}

export default DropDown
